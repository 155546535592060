<template>
  <div class="new-form">
      <div class="field">
          <p>תאריך חותמת</p>
          <input type="date" v-model="values.selected_date">
      </div>
      <div class="field">
        <p>שם הספק</p>
        <el-select style="width:100%;" v-model="values.selected_supplier" filterable placeholder="חיפוש ספק">
            <el-option
            v-for="supplier in suppliers"
            :key="supplier"
            :value="supplier"
            >
            </el-option>
      </el-select>
      </div>
      <div class="field">
          <p>שם הסניף</p>
          <el-select style="width:100%;" v-model="values.selected_branche" filterable placeholder="חיפוש סניף">
            <el-option
            v-for="branche in branches"
            :key="branche"
            :value="branche"
            >
            </el-option>
        </el-select>
      </div>
      <div class="field btn">
          <Button
                label="שלח טופס"
                style="width:260px; height:50px; border-radius:5px; font-size: 25px; "
                class="p-button-success"
                @click="handle_submit"
            />
      </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import { ref } from '@vue/reactivity';
import store from '../../../../store';
import {alert} from '../../../../Methods/Msgs'
import {projectFirestore,firebase} from '../../../../firebase/config'
import {get_branches_from_db,get_suppliers_from_db} from '../../../../Methods/rosman_shivuk_funcs'
import { computed, onMounted } from '@vue/runtime-core';

export default {
    components:{InputText},
    emits:['close'],
    setup(_,{emit}){
        const error_msg = ref('')
        const user_name = ref(computed(()=>{
            return store.getters.user.display_name
        }))

        const values = ref({
            createdAt:new Date(),
            selected_date:'',
            selected_supplier:'',
            selected_branche:'',
            month:'',
            year:'',
            user_name:user_name.value
        })
        
        const suppliers = ref([])
       
        const branches = ref([])

        const handle_submit = async()=>{
            if(!validation()){
                alert('error','שגיאה',error_msg.value)
            }
            else{
                values.value.month = convert_number_to_month(new Date(values.value.selected_date).getMonth())
                values.value.year = new Date(values.value.selected_date).getFullYear()
                values.value.selected_date = new Date(values.value.selected_date)
                console.log(values.value);
                await store_in_db()
                await increment()
                alert('success','הצלחה','הטופס נשלח בהצלחה')
                .then(()=>{
                    emit('close')
                })
            }
        }

        const store_in_db = async()=>{
            const doc = projectFirestore.collection('Applications').doc('ElRmAMsdxLy15tL4oFiF').collection('Forms').doc()
            await doc.set({...values.value,id:doc.id})
        }

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'selected_date':
                        if(!value) {
                            error_msg.value='עליך לבחור תאריך'
                            return false
                        }
                        break;
                    case 'selected_supplier':
                        if(!value) {
                            error_msg.value = 'עליך לבחור ספק'
                            return false
                        }
                        break;
                    case 'selected_branche':
                        error_msg.value = 'עליך לבחור סניף'
                        if(!value) {
                            return false
                        }
                        break;
                    default:
                        break;
                }
            }
            return true
        }

        const convert_number_to_month = (number)=>{
            switch (number) {
                case 0:
                    return 'ינואר'
                case 1:
                    return 'פברואר'
                case 2:
                    return 'מרץ'
                case 3:
                    return 'אפריל'
                case 4:
                    return 'מאי'
                case 5:
                    return 'יוני'
                case 6:
                    return 'יולי'
                case 7:
                    return 'אוגוסט'
                case 8:
                    return 'ספטמבר'
                case 9:
                    return 'אוקטובר'
                case 10:
                    return 'נובמבר'
                case 11:
                    return 'דצמבר'
                default:
                    break;
            }
        }

        const increment = async ()=>{
            await projectFirestore.collection('Applications').doc('ElRmAMsdxLy15tL4oFiF')
            .update({
                forms_number:firebase.firestore.FieldValue.increment(1),
            })
        }

        onMounted(async()=>{
           suppliers.value = await get_suppliers_from_db()
           branches.value = await get_branches_from_db()
        })  

        return{handle_submit,values,suppliers,branches}
    }
}
</script>

<style scoped>
    .new-form{
        margin-top: 15px;
        width: 40vw;
        height: 35vh;
        min-width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .field{
        width: 100%;
        height: fit-content;
        margin-bottom: 15px;
    }
    .field.btn{
        text-align: center;
    }
    input[type="date"] {
        width: 100%;
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    p{
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 5px;
    }
    ::v-deep(.p-button){
        background: #333;
        border-radius: unset;
        border:unset;
        transition: unset;

    }
    ::v-deep(.p-button-success){
        background: #5E8F32;
    }
    ::v-deep(.p-button:hover){
        background: #333;
    }
    @media screen and (max-width: 600px) {
        .new-form {
            height: 50vh;
        }
    }
</style>